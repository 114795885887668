import React, { Component } from "react";

class Card extends Component {
  render() {
    if (this.props.type === "education") {
      return (
        <div className="card">
          <div className="type">
            <p>Educación</p>
          </div>
          {this.props.contenido.map((content, i) => {
            return (
              <div key={i}>
                <div className="date">
                  <p>{content.date}</p>
                </div>
                <div className="school">
                  <p>
                    <span>{content.carrera}</span>
                    {" - " + content.school}
                  </p>
                </div>
                <div className="divider"></div>
              </div>
            );
          })}
        </div>
      );
    } 
    else if (this.props.type === "experience") {
      return (
        <div className="card-exp">
          <div className="btns">
            <div className="type">
                <p>Experiencia</p>
            </div>
            <div className="empresa">
                <p>{this.props.empresa}</p>
            </div>
          </div>
          <div className="puesto">
            <h1>{this.props.puesto}</h1>
          </div>
          <div className="horario">
            <p>{this.props.horario}</p>
          </div>
          <div className="fecha">
            <p>{this.props.fecha}</p>
          </div>
          <div className="divider"></div>
          <div className="labor">
            <p>{this.props.labor}</p>
          </div>
        </div>
      );
    }
    else if (this.props.type === "skills") {
        return (
          <div className="card">
            <div className="type">
              <p>Habilidades</p>
            </div>
            {
                this.props.skills.map((skill, i) =>{
                    return (
                        <div className="container-bar" key={i}>
                            <div className="encabezado">
                                <div>
                                    <p className="p-title">{skill.title}</p>
                                </div>
                                <div>
                                    <p className="p-load">{skill.progress}%</p>
                                </div>
                            </div>
                            <div className="progress-bar">
                                <progress  max="100" value={skill.progress}></progress>
                            </div>
                        </div>
                    )
                })
            }
          </div>
        );
      }
  }
}

export default Card;
