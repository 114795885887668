import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// COMPONENETES PARA QUE FUNIONE EL SITIO
import Header from "./Header";
import Slider from './Slider';
import About from './About';
import Title from './Title';
import Skills from './Skills';
import WhyChoose from './WhyChoose';
import Portafolio from './Portafolio';
import Contact from './Contact';
import Footer from './Footer';
import Whatsapp from './Whatsapp';

class Router extends Component{
    render(){
        return(
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" />                  
                </Switch>
                <React.Fragment>
                    <Header isHome={true}/>
                    <Slider/>
                    <Title 
                        heading="Acerca de mí"
                        subheading="Desarrollador de software en Tijuana"
                        text="Bienvenido, en esta sección hablaré un poco sobre mí para que puedas conocerme un poco mejor, si deseas contactarme recuerda que puedes hacerlo mediante Whatsapp, correo o Facebook"
                    />
                    <About />
                    <Title 
                        heading="Experiencia y habilidades"
                        subheading="SEO en Tijuana"
                        text="Tengo un largo camino en el desarrollo de software desde empresas pequeñas, en la escuela y hasta en empresas a nivel internacional con una amplia experiencia en el desarrollo de software."
                    />
                    <Skills />
                    <Title 
                        heading="¿Por qué elegirme?"
                        subheading="Te estarás preguntando"
                        text="Por mi compromiso, facilidad de aprender cosas nuevas y mis ganas de aplicar siempre la mejora continua."
                        color="black"
                    />
                    <WhyChoose/>
                    <Title 
                        heading="Portafolio"
                        subheading="Páginas web en Tijuana"
                        text="Revisa mi portafolio y proyectos que he realizado a lo largo de mi carrera como desarrollador de software"
                    />
                </React.Fragment>
                <Portafolio />
                {/* <Contact /> */}
                <Footer year={2024}/>
                <Whatsapp />
            </BrowserRouter>
        );
    }
}

export default Router;