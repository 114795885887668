import Router from './components/Router';
import './assets/css/global.css';
import React from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-210396837-1');
ReactGA.pageview(window.location.pathname + window.location.search)

function App() {
  return (
    <Router />
  );
}

export default App;
