import React, {Component} from 'react';

import imgPerfil from '../assets/img/perfil.jpeg';

class About extends Component{
    render(){
        return(
            <div className="container" id="acerca">
                <div className="flex">
                    <div className="left">
                        <img className="perfil" src={imgPerfil} alt={imgPerfil}/>
                    </div>
                    <div className="right">
                    <p className="subheading">Hola, mi nombre es<span className="line"></span></p>
                        <h1 className="titulo">Manuel Eduardo Blanco Melendez</h1>
                        <p className="parrafo">Soy una persona integra y con valores que busca siempre hacer las cosas de la mejor manera y optimizando todo aquello que sea posible, me considero una persona limpia y ordenada, <span className="red">no tengo problemas con aprender cosas nuevas y adaptarme a lo desconocido</span>.</p>
                    <div className="divider"></div>
                    <h3 className="info-personal">Información personal</h3>
                    <div className="flex-info">
                        <div>
                            <p>Nombre: <span>Manuel Blanco</span></p>
                            <p>Edad: <span>26 años</span></p>
                            <p>Nacionalidad: <span>Mexicana</span></p>
                            <p>Idioma: <span>Español, Inglés 40%</span></p>
                        </div>
                        <div>
                            <p>Dirección: <span>Francisco Villa</span></p>
                            <p>Teléfono: <span>(664) 460 8615</span></p>
                            <p>Correo: <span>programadorblanco@gmail.com</span></p>
                            <p>Disponibilidad: <span>disponibilidad de horario</span></p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;