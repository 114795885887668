import React, {Component} from 'react';
import ReactGa from 'react-ga';

import imgSearch from '../assets/img/search.png';
import imgUrologia from '../assets/img/proyectos/urologia.jpeg';
import imgDrink from '../assets/img/proyectos/drink.jpeg';
import imgLoubet from '../assets/img/proyectos/loubet.jpeg';
import imgTecompro from '../assets/img/proyectos/tecomprotucasa.jpeg';
import imgAcit from '../assets/img/proyectos/acit.jpeg';
import imgArza from '../assets/img/proyectos/arza.jpeg';
import imgBlu from '../assets/img/proyectos/blue.jpeg';
import imgCirc from '../assets/img/proyectos/circ.jpeg';
import imgDrhuacuz from '../assets/img/proyectos/drhuacuz.jpeg';
import imgEsprofeso from '../assets/img/proyectos/esprofeso.jpeg';
import imgGahir from '../assets/img/proyectos/gahir.jpeg';
import imgInnova from '../assets/img/proyectos/innova.jpeg';
import imgInternista from '../assets/img/proyectos/inernista.jpeg';
import imgNeuro from '../assets/img/proyectos/neuro.jpeg';
import imgOdessa from '../assets/img/proyectos/odesa.jpeg';
import imgOtorrino from '../assets/img/proyectos/otorrino.jpeg';
import imgVmg from '../assets/img/proyectos/vmg.jpeg';
import imgCorp from '../assets/img/proyectos/corp.jpeg';
import imgBody from '../assets/img/proyectos/bodyart.jpeg';
import imgIncognita from '../assets/img/proyectos/incognita.jpeg';
import imgSps from '../assets/img/proyectos/sps.jpeg';
import imgBih from '../assets/img/proyectos/bih.jpeg';
import imgDos from '../assets/img/proyectos/dosmanos.jpeg';
import imgCal from '../assets/img/proyectos/california.jpeg';
import imgKul from '../assets/img/proyectos/kulichi.jpeg';
import imgMacias from '../assets/img/proyectos/macias.jpeg';
import imgLabor from '../assets/img/proyectos/labor.jpeg';
import imgRental from '../assets/img/proyectos/rental.jpeg';
import imgJcd from '../assets/img/proyectos/jcd.jpeg';
import imgGmex from '../assets/img/proyectos/gmex.jpeg';
import imgMymobile from '../assets/img/proyectos/mymobile.jpeg';
import imgPriisma from '../assets/img/proyectos/priisma.jpeg';
import imrTRAAC from '../assets/img/proyectos/TRAAC.png';
import imgIMR from '../assets/img/proyectos/IMR.png';
import imgSTF from '../assets/img/proyectos/stf.png';


class Portafolio extends Component{

    state = {
        works: [
            {
                by: "bilingue all",
                img: imgIMR,
                enlace: "#",
                title: "imr imrequest"
            },
            {
                by: "bilingue all",
                img: imrTRAAC,
                enlace: "#",
                title: "traac TRAAC"
            },
            {
                by: "bilingue all",
                img: imgSTF,
                enlace: "#",
                title: "stf stop to fix stoptofix"
            },
            {
                by: "bilingue all",
                img: imgMymobile,
                enlace: "#",
                title: "mymobile Mymobile"
            },
            {
                by: "all",
                img: imgDrink,
                enlace: "https://murosfirme.com/subdomains/drinkgame/",
                title: "drinkgame DrinkGame drinkgame drink drink game"
            },
            {
                by: "all",
                img: imgPriisma,
                enlace: "#",
                title: "priisma Priisma Generacion generacion"
            },
            {
                by: "informativas all",
                img: imgUrologia,
                enlace: "https://www.urologiatijuana.com/",
                title: "urologia tijuana Urologia UrologiaTijuana"
            },
            {
                by: "tiendas all",
                img: imgLoubet,
                enlace: "https://loubet.com.mx",
                title: "loubet Loubet"
            },
            {
                by: "informativas all",
                img: imgAcit,
                enlace: "#", 
                title: "aciteducacion AcitEducacion Acit acit"
            },
            {
                by: "landing all",
                img: imgTecompro,
                enlace: "#", 
                title: "tecomprotucasa tecompro te compro Tecomprocasa Te Compro"
            },
            {
                by: "informativas all",
                img: imgArza,
                enlace: "https://arzaseguridad.com/", 
                title: "arzaseguridad arza Arzaseguridad Arza"
            },
            {
                by: "informativas all",
                img: imgBlu,
                enlace: "#", 
                title: "blueconsa Blueconsa blue Blue"
            },
            {
                by: "tiendas all",
                img: imgCirc,
                enlace: "https://circcurer.com/", 
                title: "circurer Circurer"
            },
            {
                by: "all bilingue",
                img: imgDrhuacuz,
                enlace: "https://drhuacuz.com/", 
                title: "drhuacuz Drhuacuz Dr huacuz"
            },
            {
                by: "all bilingue",
                img: imgEsprofeso,
                enlace: "https://esprofesso.com/", 
                title: "esprofeso Esprofeso esprofesso Esprofesso"
            },
            {
                by: "all bilingue",
                img: imgGahir,
                enlace: "https://gahir.mx/", 
                title: "gahir Gahir"
            },
            {
                by: "all bilingue",
                img: imgInnova,
                enlace: "http://innovatijuana.com/", 
                title: "innovatijuana InnovaTijuana Innova innova tijuana"
            },
            {
                by: "all informativas",
                img: imgInternista,
                enlace: "#", 
                title: "medicointernista internista Medicointernista"
            },
            {
                by: "all informativas",
                img: imgNeuro,
                enlace: "https://neurocirujanotijuana.com/", 
                title: "neurocirujano neuro Neuro Neurocirujano"
            },
            {
                by: "all informativas",
                img: imgOdessa,
                enlace: "https://odessaturismo.com.mx/", 
                title: "oddessa odesa Odessa Oddessa turismo Turismo"
            },
            {
                by: "all informativas",
                img: imgOtorrino,
                enlace: "https://otorrinotecate.com/", 
                title: "otorrinotecate Otorrinotecate Otorrino otorrino"
            },
            {
                by: "all bilingue",
                img: imgVmg,
                enlace: "https://vmgrenovation.com/", 
                title: "vmg Vmg"
            },
            {
                by: "all landing",
                img: imgCorp,
                enlace: "https://xtructuralcorp.com/", 
                title: "Corp corp xtructural Xtructural"
            },
            {
                by: "all bilingue",
                img: imgBody,
                enlace: "https://bodyartsurgical.com/", 
                title: "Bodyartsurgical bodyart Body body"
            },
            {
                by: "all tiendas",
                img: imgIncognita,
                enlace: "https://incognitasmokeshop.com/", 
                title: "incognita Incognita"
            },
            {
                by: "all informativas",
                img: imgSps,
                enlace: "https://spsindustrial.com.mx/", 
                title: "sps Sps"
            },
            {
                by: "all bilingue tiendas",
                img: imgDos,
                enlace: "https://dosmanoscoffeeroasters.com/", 
                title: "dosmanos Dosmanos coffe Coffe"
            },
            {
                by: "all informativas",
                img: imgBih,
                enlace: "https://bihcenter.com/", 
                title: "bihcenter Bihcenter bihcenter Bih bih"
            },
            {
                by: "all informativas",
                img: imgCal,
                enlace: "https://californiahandymansd.com/", 
                title: "california California handyman Handyman"
            },
            {
                by: "all informativas",
                img: imgKul,
                enlace: "https://kulichi61.com/", 
                title: "kulichi Kulichi"
            },
            {
                by: "all informativas",
                img: imgMacias,
                enlace: "https://maciasmadridconsultores.com/", 
                title: "Maciasmadrid maciasmadrid"
            },
            {
                by: "all informativas",
                img: imgLabor,
                enlace: "https://laborlawpyme.com/", 
                title: "labor laborpyme Laborpyme"
            },
            {
                by: "all informativas",
                img: imgLabor,
                enlace: "https://laborlawpyme.com/", 
                title: "labor laborpyme Laborpyme"
            },
            {
                by: "all tiendas",
                img: imgRental,
                enlace: "https://rentalrosarito.com/", 
                title: "rental Rental rentalrosarito"
            },
            {
                by: "all informativas",
                img: imgJcd,
                enlace: "https://hostingtijuana.com/jcdplumbing/", 
                title: "jcd JCD Jcd plumbing"
            },
            {
                by: "all tiendas",
                img: imgGmex,
                enlace: "https://hostingtijuana.com/gmexwire/", 
                title: "gmex Gmex"
            }
        ],
        showWorks: [],
        disabled: true
    }

    Filtro = (filtro) =>{
        var newArray = [];
        this.state.works.filter((work) =>{
            if(work.by.includes(filtro)){
                newArray.push(work)
            }
        });
        this.setState({
            showWorks: newArray
        })
        if(!this.state.disabled){
            var slide1 = document.querySelector('.slide-1');
            var slide2 = document.querySelector('.slide-2');
            var slide3 = document.querySelector('.slide-3');
            var slide4 = document.querySelector('.slide-4');

            var div1 = document.querySelector('.slide1');
            var div2 = document.querySelector('.slide2');
            var div3 = document.querySelector('.slide3');
            var div4 = document.querySelector('.slide4');

            var change_slide = document.querySelector('.change-slide');
            slide1.style.display = "flex";
            slide2.style.display = "none";
            slide3.style.display = "none";
            slide4.style.display = "none";
            div1.style.background = "#F52225";
            div2.style.background = "#000";
            div3.style.background = "#000";
            div4.style.background = "#000";
            change_slide.style.display = "none";
            if(newArray.length <= 9){
                change_slide.style.display = "none";
            }
            else if(newArray.length >= 10 && newArray.length <= 18){
                change_slide.style.display = "flex";
                div3.style.display = "none";
                div4.style.display = "none";
            }
            else if(newArray.length >= 19 && newArray.length <= 27){
                change_slide.style.display = "flex";
                div3.style.display = "flex";
                div4.style.display = "none";
            }
            else if(newArray.length >= 28 && newArray.length <= 36){
                change_slide.style.display = "flex";
                div4.style.display = "flex";
                div3.style.display = "flex";
            }
        }

    }

    Search = () =>{
        var input_value = document.querySelector('.txt-search').value;
        var newArray = [];
        this.state.works.filter((work) =>{
            if(work.title.includes(input_value)){
                newArray.push(work)
            }
        });
        this.setState({
            showWorks: newArray
        })
        // var input_value = document.querySelector('.txt-search');
        input_value.value = "";

        var slide1 = document.querySelector('.slide-1');
            var slide2 = document.querySelector('.slide-2');
            var slide3 = document.querySelector('.slide-3');
            var slide4 = document.querySelector('.slide-4');

            var div1 = document.querySelector('.slide1');
            var div2 = document.querySelector('.slide2');
            var div3 = document.querySelector('.slide3');
            var div4 = document.querySelector('.slide4');

            var change_slide = document.querySelector('.change-slide');
            slide1.style.display = "flex";
            slide2.style.display = "none";
            slide3.style.display = "none";
            slide4.style.display = "none";
            div1.style.background = "#F52225";
            div2.style.background = "#000";
            div3.style.background = "#000";
            div4.style.background = "#000";
            change_slide.style.display = "none";
            if(newArray.length <= 9){
                change_slide.style.display = "none";
            }
            else if(newArray.length >= 10 && newArray.length <= 18){
                change_slide.style.display = "flex";
                div3.style.display = "none";
                div4.style.display = "none";
            }
            else if(newArray.length >= 19 && newArray.length <= 27){
                change_slide.style.display = "flex";
                div3.style.display = "flex";
                div4.style.display = "none";
            }
            else if(newArray.length >= 28 && newArray.length <= 36){
                change_slide.style.display = "flex";
                div4.style.display = "flex";
                div3.style.display = "flex";
            }


    }

    Changeslide = (actual) =>{
        var slide1 = document.querySelector('.slide-1');
        var slide2 = document.querySelector('.slide-2');
        var slide3 = document.querySelector('.slide-3');
        var slide4 = document.querySelector('.slide-4');
        
        var div1 = document.querySelector('.slide1');
        var div2 = document.querySelector('.slide2');
        var div3 = document.querySelector('.slide3');
        var div4 = document.querySelector('.slide4');
        if(actual === 1){
            slide1.style.display = "flex";
            slide2.style.display = "none";
            slide3.style.display = "none";
            slide4.style.display = "none";

            div1.style.background = "#F52225";
            div2.style.background = "#000";
            div3.style.background = "#000";
            div4.style.background = "#000";
        }
        else if(actual === 2){
            slide1.style.display = "none";
            slide2.style.display = "flex";
            slide3.style.display = "none";
            slide4.style.display = "none";

            div1.style.background = "#000";
            div2.style.background = "#F52225";
            div3.style.background = "#000";
            div4.style.background = "#000";
        }
        else if(actual === 3){
            slide1.style.display = "none";
            slide2.style.display = "none";
            slide3.style.display = "flex";
            slide4.style.display = "none";

            div1.style.background = "#000";
            div2.style.background = "#000";
            div3.style.background = "#F52225";
            div4.style.background = "#000";

        }
        else if(actual === 4){
            slide1.style.display = "none";
            slide2.style.display = "none";
            slide3.style.display = "none";
            slide4.style.display = "flex";

            div1.style.background = "#000";
            div2.style.background = "#000";
            div3.style.background = "#000";
            div4.style.background = "#F52225";
        }
    }

    // componentMount(){
        
    // }

    componentDidMount(){
        this.Filtro("all");
        this.setState({
            disabled: false
        })
    }

    SendAnalitycs = (url) =>{
        ReactGa.event({
            category: "portafolio",
            action: url
        });
    }

    render(){
        return(
            <div className="container portoflio" id="proyectos">
                <section className="sidebar">
                    <div className="search"></div>
                    <div className="filters">
                        <div className="by-title">
                            <h1>Filtro de búsqueda</h1>
                        </div>
                        <div className="divider"/>
                        <div className="by-date">
                            <div>
                                <input type="text" className="txt-search" placeholder="Ingrese proyecto..."/>
                                <img className="img-search" src={imgSearch} alt={imgSearch} onClick={this.Search}/>
                            </div>
                        </div>
                        <div className="by-a">
                            <ul>
                                <li onClick={() => this.Filtro("all")}><span className="gray">Filtrar por:</span> Todos</li>
                                <li onClick={() => this.Filtro("tiendas")}><span className="gray">Filtrar por:</span> Tiendas en línea</li>
                                <li onClick={() => this.Filtro("landing")}><span className="gray">Filtrar por:</span> LandingPage</li>
                                <li onClick={() => this.Filtro("bilingue")}><span className="gray">Filtrar por:</span> Páginas Bilingues</li>
                                <li onClick={() => this.Filtro("informativas")}><span className="gray">Filtrar por:</span> Páginas informativas</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="works">
                    <div className="mostrados">
                        <p>Mostrados {this.state.showWorks.length} proyectos</p>
                    </div>
                    <div className="proyectos slide-1">
                        {
                            this.state.showWorks.map((work, i) =>{
                                if(i < 9){
                                    return (
                                        <div className={work.by + ' proyecto'} key={i}>
                                            {
                                                work.enlace == "#" 
                                                ? <img src={work.img} alt={work.img}/> 
                                                : 
                                                <a onClick={() => this.SendAnalitycs(work.enlace)} href={work.enlace} target="_blank" rel="noreferrer">
                                                    <img src={work.img} alt={work.img}/>
                                                </a>
                                            }
                                        </div>
                                    )
                                }
                                else{
                                    return true
                                }
                            })
                        }
                        </div>
                        <div className="proyectos slide-2">
                        {
                            this.state.showWorks.map((work, i) =>{
                                if(i < 18 && i > 8){
                                    return (
                                        <div className={work.by + ' proyecto'} key={i}>
                                            <a onClick={() => this.SendAnalitycs(work.enlace)} href={work.enlace} target="_blank" rel="noreferrer">
                                                <img src={work.img} alt={work.img}/>
                                            </a>
                                        </div>
                                    )
                                }
                            })
                        }
                        </div>
                        <div className="proyectos slide-3">
                        {
                            this.state.showWorks.map((work, i) =>{
                                if(i > 17 && i < 27){
                                    return (
                                        <div className={work.by + ' proyecto'} key={i}>
                                            <a onClick={() => this.SendAnalitycs(work.enlace)} href={work.enlace} target="_blank" rel="noreferrer">
                                                <img src={work.img} alt={work.img}/>
                                            </a>
                                        </div>
                                    )
                                }
                            })
                        }
                        </div>
                        <div className="proyectos slide-4">
                        {
                            this.state.showWorks.map((work, i) =>{
                                if(i > 26){
                                    return (
                                        <div className={work.by + ' proyecto'} key={i}>
                                            <a onClick={() => this.SendAnalitycs(work.enlace)} href={work.enlace} target="_blank" rel="noreferrer">
                                                <img src={work.img} alt={work.img}/>
                                            </a>
                                        </div>
                                    )
                                }
                            })
                        }
                        </div>
                        <div className="change-slide">
                            <div className="slide1" onClick={() => this.Changeslide(1)}>1</div>
                            <div className="slide2" onClick={() => this.Changeslide(2)}>2</div>
                            <div className="slide3" onClick={() => this.Changeslide(3)}>3</div>
                            <div className="slide4" onClick={() => this.Changeslide(4)}>4</div>
                        </div>
                </section>
            </div>
        );
    }
}

export default Portafolio;