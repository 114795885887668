import React, {Component} from 'react';
import ReactGa from 'react-ga';

import imgWhatsapp from '../assets/img/whatsapp.png';


class Whatsapp extends Component{

    SendAnalitycs = () =>{
        ReactGa.event({
            category: 'whatsapp',
            action: "click in button whatsapp"
        });
    }

    render(){
        return(
            <a href="https://wa.link/7bq90u" target="_blank" className="fix" rel="noreferrer">
                <img onClick={this.SendAnalitycs} className="whats" src={imgWhatsapp} alt={imgWhatsapp}/>
            </a>
        );
    }
}

export default Whatsapp;