import React, {Component} from 'react';
import emailjs from 'emailjs-com';

import imgMail from '../assets/img/correo.png';
import imgPhone from '../assets/img/telefono.png';
import imgMap from '../assets/img/map.png';


class Contact extends Component{

    state = {
        validacion: true
    }

    // NAMEREF CONTIENE EL VALOR DEL INPUT MEDIANTE REFERENCIAS
    nameRef = React.createRef();
    telRef = React.createRef();
    mailRef = React.createRef();
    subRef = React.createRef();
    messRef = React.createRef();

    SendMail = (e) =>{
        e.preventDefault();
        if(
            this.nameRef.current.value === "" ||
            this.mailRef.current.value === "" ||
            this.subRef.current.value === "" 
        ){
            if(this.nameRef.current.value === ""){
                this.nameRef.current.classList.add("error");
            }
            if(this.mailRef.current.value === ""){
                this.mailRef.current.classList.add("error");
            }
            if(this.subRef.current.value === ""){
                this.subRef.current.classList.add("error");
            }
        }
        else{
            // ENVIO DE DATOS MEDIANTE EMAILJS
            emailjs.sendForm('gmail', 'service_ug8i718', "#form", 'template_4m9l4kb')
            .then((result) => {
                e.target.reset(); // BORRA LOS CAMPOS DEL FORM AL ENVIAR
                this.nameRef.current.classList.remove("error");
                this.mailRef.current.classList.remove("error");
                this.subRef.current.classList.remove("error");
                document.querySelector('#respuesta').innerHTML = "Mensaje enviado correctamente";
                document.querySelector('.popup').style.display = "flex";
            }, (error) => {
                document.querySelector('#respuesta').innerHTML = "Hubo un error, lo estamos solucionando";
                document.querySelector('.popup').style.display = "flex";
            });
        }
    }

    HidePopup = () =>{
        document.querySelector('.popup').style.display = "none";
    }

    render(){
        return(
            <div className="container" id="contacto">
                <div className="flex-contact">
                    <aside>
                        <div className="text title">
                            <p className="subheading">Diseño web en Tijuana<span className="line"></span></p>
                            <h1 className="heading">Contacto</h1>
                            <p className="text">Si llegaste hasta aquí no olvides que me puedes contactar mediante correo eléctronico, llamada, whatsapp o a trávez de redes sociales</p>
                        </div>
                        <div className="icons-contact">
                            <div className="flex-icons-2">
                                <img src={imgPhone} alt={imgPhone}/>
                                <p>(664) 460 8615</p>
                            </div>
                            <div className="flex-icons-2">
                                <img src={imgMail} alt={imgMail}/>
                                <p>programadorblanco@gmail.com</p>
                            </div>
                            <div className="flex-icons-2">
                                <img src={imgMap} alt={imgMap}/>
                                <p>Tijuana, Baja California México</p>
                            </div>
                        </div>
                    </aside>
                    <div className="rigth-contact">
                        <form id="form" onSubmit={this.SendMail}>
                            <h3>No busques más, enviame un mensaje<br/><span className="gray">Y con gusto me comunicaré con usted a la brevedad</span></h3>
                            <div className="middle mar">
                                <div className="container-input">
                                    <input type="text" placeholder="Nombre *" ref={this.nameRef} name="name" autoComplete="off"/>
                                </div>
                                <div className="container-input">
                                    <input type="text" placeholder="Teléfono"
                                    ref={this.telRef} name="phone" autoComplete="off"/>
                                </div>
                            </div>
                            <div className="middle mar">
                                <div className="container-input">
                                    <input type="email" placeholder="Correo *"
                                    ref={this.mailRef} name="mail" autoComplete="off"/>
                                </div>
                                <div className="container-input">
                                    <input type="text" placeholder="Asunto *"
                                    ref={this.subRef} name="subject" autoComplete="off"/>
                                </div>
                            </div>
                            <div className="mar">
                                <textarea placeholder="Mensaje" ref={this.messRef} name="message" autoComplete="off"></textarea>
                            </div>
                            <div className="mar">
                                <input type="submit" value="Enviar"/>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="popup">
                    <h3 id="respuesta">Mensaje vacio</h3>
                    <button onClick={this.HidePopup}>Aceptar</button>
                </div>
            </div>
        );
    }
}

export default Contact;