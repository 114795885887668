import React, {Component} from 'react';

class Footer extends Component{
    render(){
        return(
                <footer>
                    <p>Copyright {this.props.year}, All Right Reserved. Diseño y desarrollo Web por: <span className="red">Eduardo Blanco</span></p>
                </footer>
        );
    }
}

export default Footer;