import React, {Component} from 'react';
import ReactGa from 'react-ga';
import cv from "../assets/cv.pdf";

class Slider extends Component{

    ViewGitHub = () =>{
        window.open(cv);
        ReactGa.event({
            category: 'github',
            action: 'click button github from slider'
        });
    }

    render(){
        return(
            <div className="slider" id="slider">
                <div className="box-left">
                    <h3>Hola, bienvenido</h3>
                    <h1>Soy desarrollador</h1>
                    <h2>De software en Tijuana</h2>
                    <p>Como ingeniero de software, mi labor no solo radica en la creación de código, sino también en comprender y mejorar continuamente los procesos de desarrollo de software. La colaboración, la comunicación efectiva y la capacidad para trabajar en equipo considero es esencial, ya que los proyectos requieren la sinergia de talentos diversos.</p>
                    <a onClick={this.ViewGitHub} className="btn-portfolio" rel="noreferrer">Descargar CV</a>
                </div>
                <div className="box-rigth"></div>
            </div>
        );
    }
}

export default Slider;