import React, { Component } from "react";
import ReactGA from 'react-ga';

import Card from "./Card";

class Skills extends Component {

  constructor(props){
    super(props);
    this.Slider = this._Slider.bind(this.props.actual);
  }

  componentDidUpdate(nextProps){
    this.Slider = this._Slider.bind(this.nextProps.actual);
  }

  _Slider(actual, event){
    var educacion = document.querySelector('.educacion');
    var experiencia = document.querySelector('.experiencia');
    var habilidades = document.querySelector('.habilidades');

    var btnEdu = document.querySelector('.education');
    var btnExp = document.querySelector('.experience');
    var btnSkills = document.querySelector('.skills');
    if(actual === 'educacion'){
      educacion.style.display = "flex";
      experiencia.style.display = "none";
      habilidades.style.display = "none";

      btnEdu.style.background = "#f52225";
      btnEdu.style.color = "#fff";

      btnExp.style.background = "none";
      btnExp.style.color = "#A3A9BC";

      btnSkills.style.background = "none";
      btnSkills.style.color = "#A3A9BC";

      ReactGA.event({
        category: 'skills',
        action: "click button education from skills"
      })
      
    }
    else if(actual === 'experiencia'){
      educacion.style.display = "none";
      experiencia.style.display = "flex";
      habilidades.style.display = "none";

      btnEdu.style.background = "none";
      btnEdu.style.color = "#A3A9BC";

      btnExp.style.background = "#f52225";
      btnExp.style.color = "#fff";

      btnSkills.style.background = "none";
      btnSkills.style.color = "#A3A9BC";

      ReactGA.event({
        category: 'skills',
        action: "click button experience from skills"
      })
    }
    else{
      educacion.style.display = "none";
      experiencia.style.display = "none";
      habilidades.style.display = "flex";

      btnEdu.style.background = "none";
      btnEdu.style.color = "#A3A9BC";

      btnExp.style.background = "none";
      btnExp.style.color = "#A3A9BC";

      btnSkills.style.background = "#f52225";
      btnSkills.style.color = "#fff";

      ReactGA.event({
        category: 'skills',
        action: "click button skills from skills"
      })
    }
  }

  render() {
    return (
      <div className="container" id="cv">
        <div className="buttons">
          <button className="btn education" onClick={() => this._Slider("educacion")}>
            Educación
          </button>
          <button className="btn experience" onClick={() => this._Slider("experiencia")}>
            Experiencia
          </button>
          <button className="btn skills" onClick={() => this._Slider("habilidades")}>
            Habilidades
          </button>
        </div>
        <div className="cards educacion">
          <Card
            type="education"
            contenido={[
              {
                date: "2010-2013",
                school: "Secundaria #28 Dominga Marquez y Marquez",
                carrera: "Informática",
              },
              {
                date: "2013-2016",
                school: "Preparatoria Federal Lázaro Cárdenas",
                carrera: "Arquitectura",
              },
              {
                date: "2017-2022",
                school: "Instituto Tecnológico de Tijuana",
                carrera: "Ing. en sistemas computacionales",
              },
            ]}
          />
          <Card
            type="education"
            contenido={[
              {
                date: "2023",
                school: "Pluralsight",
                carrera: "Prioritizing tasks and managing time for greater productivity",
              },
              {
                date: "2022",
                school: "Udemy",
                carrera: "Entity Framework, Solid y patrones de diseño",
              },
              {
                date: "2022",
                school: "Udemy",
                carrera: "Vue",
              },
            ]}
          />
          <Card
            type="education"
            contenido={[
              {
                date: "2020",
                school: "Udemy",
                carrera: "Diseño de páginas web con Wordpress",
              },
              {
                date: "2020",
                school: "Udemy",
                carrera: "Diseño de aplicaciones web en Adobe XD",
              },
              {
                date: "2021",
                school: "Udemy",
                carrera: "Máster en React Js, Node Js y Node Js",
              },
            ]}
          />
          <Card
            type="education"
            contenido={[
              {
                date: "2018",
                school: "Udemy",
                carrera: "Página web con HTML y CSS - Flexbox",
              },
              {
                date: "2019",
                school: "Udemy",
                carrera: "Máster en Js, Jquery y Node Js",
              },
              {
                date: "2020",
                school: "Udemy",
                carrera: "Master en Php, Ajax y Js",
              },
            ]}
          />
          
          
        </div>
        <div className="cards experiencia">
          <Card
            type="experience"
            puesto="Software Engineer"
            horario="Tiempo completo | Oficina"
            fecha="Marzo 2022 - Actualmente"
            empresa="Turbotec"
            labor="Mis actividades del día a día consisten en desarrollar funcionalidades para varias aplicaciones del departamento, dar soporte a clientes y mejorar un par de procesos que se tienen en la compañía. También soy el encargado de los practicantes; me enfoco en ayudarlos a seguir creciendo y resolviendo sus dudas cuando las tienen en el momento de desarrollar. Trabajo con metodología Agile, con el marco SCRUM."
          />
          <Card
            type="experience"
            puesto="Desarrollador full stack"
            horario="Medio tiempo | Freelance"
            fecha="Septiembre 2021 - Marzo 2022"
            empresa="MyMobile"
            labor="Desarrollé una plataforma para gestionar la información personal de los nuevos empleados, generando documentos digitales con firma digital. En la sección de administración, se podía solicitar más información a los usuarios y enviar correos a los mismos. También manejé gráficos del porcentaje de usuarios nuevos, porcentaje de usuarios faltantes de información, entre otras funcionalidades."
          />
          <Card
            type="experience"
            puesto="Desarrollador web"
            horario="Tiempo completo | Oficina"
            fecha="Agosto 2020 - Septiembre 2021"
            empresa="Grupo Gurú"
            labor="Hice páginas web en WordPress, utilicé JavaScript y PHP cuando se requería hacer algo que WordPress no era capaz de hacer. También manejaba las páginas desde un WHM y un cPanel. Realicé configuración POP3 para cuentas de correo electrónico, entre otras tareas."
          />
          <Card
            type="experience"
            puesto="Asistente informático"
            horario="Medio tiempo | Oficina"
            fecha="Julio 2018 - Enero 2019"
            empresa="Agencia aduanal Serana"
            labor="Era asistente informático de la presidenta de agentes aduanales en Tijuana. En su mayoría, estuve capacitándola en paquetería Office, desde nivel básico hasta avanzado."
          />
          <Card
            type="experience"
            puesto="Desarrollador web"
            horario="Medio tiempo | Oficina"
            fecha="Enero 2018 - Julio 2018"
            empresa="Natural Food Products"
            labor="Construí una página web básica con HTML, SASS y JavaScript para mostrar los servicios de la empresa y sus futuras visitas en las diferentes expos de la ciudad. También configuré el hosting donde estaba alojada la página y configuré correos con el protocolo POP3"
          />
        </div>
        <div className="cards habilidades">
        <Card
            type="skills"
            skills={
              [
                {
                  title: "Front End",
                  progress: 90
                },
                {
                  title: "JavaScript",
                  progress: 90
                },
                {
                  title: "Vue",
                  progress: 90
                },
                {
                  title: "React Js",
                  progress: 80
                },                
              ]
            }
          />
          <Card
            type="skills"
            skills={
              [
                {
                  title: "Back End",
                  progress: 90
                },
                {
                  title: "Node Js",
                  progress: 80
                },
                {
                  title: "Entity Framework",
                  progress: 90
                },
                {
                  title: "SOLID",
                  progress: 85
                }
              ]
            }
          />
          <Card
            type="skills"
            skills={
              [
                {
                  title: "Bases de datos",
                  progress: 90
                },
                {
                  title: "MySQL",
                  progress: 90
                },
                {
                  title: "Mongo DB",
                  progress: 80
                },
                {
                  title: "SQL",
                  progress: 90
                }
              ]
            }
          />
          <Card
            type="skills"
            skills={
              [
                {
                  title: "CI/CD",
                  progress: 70
                },
                {
                  title: "Power Bi",
                  progress: 70
                },
                {
                  title: "SSRS",
                  progress: 85
                },
                {
                  title: "GraphQl",
                  progress: 70
                },                
              ]
            }
          />
          <Card
            type="skills"
            skills={
              [
                {
                  title: ".Net Core",
                  progress: 90
                },
                {
                  title: "Net Framework",
                  progress: 90
                },
                {
                  title: "Azure DevOps",
                  progress: 80
                },
                {
                  title: "6 sigma",
                  progress: 70
                },                
              ]
            }
          />
          <Card
            type="skills"
            skills={
              [
                {
                  title: "Dependency Injection",
                  progress: 90
                },
                {
                  title: "Prioritizacion de tareas y buena productividad",
                  progress: 100
                },
                {
                  title: "Estandarizacion de procesos",
                  progress: 80
                },
                {
                  title: "Tailwind y Boostrap",
                  progress: 90
                },                
              ]
            }
          />
          <Card
            type="skills"
            skills={
              [
                {
                  title: "Metodología SCRUM",
                  progress: 90
                },
                {
                  title: "Github",
                  progress: 85
                },
                {
                  title: "Cpanel",
                  progress: 90
                },
                {
                  title: "WHM",
                  progress: 90
                }
              ]
            }
          />
          <Card
            type="skills"
            skills={
              [
                {
                  title: "PhotoShop",
                  progress: 50
                },
                {
                  title: "Adobe XD",
                  progress: 95
                },
                {
                  title: "Windows",
                  progress: 100
                },
                {
                  title: "Linux",
                  progress: 70
                }
              ]
            }
          />
          <Card
            type="skills"
            skills={
              [
                {
                  title: "Trabajo en equipo",
                  progress: 100
                },
                {
                  title: "Honesto",
                  progress: 100
                },
                {
                  title: "Responsable",
                  progress: 100
                },
                {
                  title: "Comprometido",
                  progress: 100
                }
              ]
            }
          />
        </div>
      </div>
    );
  }
}

export default Skills;
