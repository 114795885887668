import React, {Component} from 'react';

import imgAprender from '../assets/img/idea.png';
import imgCom from '../assets/img/comunicacion.png';
import imgReloj from '../assets/img/reloj.png';
import imgRes from '../assets/img/responsable.png';



class WhyChoose extends Component{
    render(){
        return(
            <div className="container black">
                <div className="flex-icons">
                    <div className="box-icon">
                        <img src={imgAprender} alt={imgAprender}/>
                        <h1 className="text-icon">Mejora continua</h1>
                    </div>
                    <div className="box-icon">
                        <img src={imgCom} alt={imgCom}/>
                        <h1 className="text-icon">Comunicación efectiva</h1>
                    </div>
                    <div className="box-icon">
                        <img src={imgReloj} alt={imgReloj}/>
                        <h1 className="text-icon">Puntualidad</h1>
                    </div>
                    <div className="box-icon">
                        <img src={imgRes} alt={imgRes}/>
                        <h1 className="text-icon">Responsable</h1>
                    </div>
                </div>
                <div className="espacio"></div>
            </div>
        );
    }
}

export default WhyChoose;