import React, {Component} from 'react';

class Title extends Component{
    render(){
        return(
            <div className={this.props.float + ' container ' + this.props.color}>
                <div className={this.props.float + "-text title"}>
                    <p className="subheading">{this.props.subheading}<span className="line"></span></p>
                    <h1 className="heading">{this.props.heading}</h1>
                    <p className="text">{this.props.text}</p>
                </div>
            </div>
        );
    }
}

export default Title;