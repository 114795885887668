import React, {Component} from "react";
import { HashLink } from 'react-router-hash-link';

import imgMenu from '../assets/img/menu.png';

class Header extends Component{

    state = {
        count: true
    }

    Scroll = () =>{
        var header = document.querySelector('header');
        if(window.scrollY >= 108){
            header.classList.add("fixed");
        }
        else{
            header.classList.remove("fixed");
        }
    }

    componentDidMount(){
        window.addEventListener("scroll", this.Scroll);
    }

    Menu = () =>{
        var ul = document.querySelector('.ul-movil');
        if(this.state.count){
            ul.classList.add('ul-movil-flex');
            this.setState({
                count: false
            })
        }
        else{
            ul.classList.remove('ul-movil-flex');
            this.setState({
                count: true
            })
        }
    }

    HideMenuMovil = () =>{
        var menu_movil = document.querySelector('.ul-movil');
        menu_movil.classList.remove('ul-movil-flex');
        this.setState({
            count: true
        })
    }

    render(){
        if(this.props.isHome){
            return(
                <React.Fragment>
                    <header className="isHome header">
                        <div className="container-logo">
                            <h1><span className="red">E</span>duardo <span className="red">B</span>lanco</h1>
                        </div>
                        <nav>
                            <ul className="menu">
                                <li><HashLink smooth to="#slider" >Inicio</HashLink></li>
                                <li><HashLink smooth to="#acerca" >Acerca de mí</HashLink></li>
                                <li><HashLink smooth to="#cv" >Curriculum Vitae</HashLink></li>
                                <li><HashLink smooth to="#proyectos" >Proyectos</HashLink></li>
                                <li className="btn-contact"><HashLink  smooth to="#contacto">Contacto</HashLink></li>
                            </ul>
                        </nav>
                    </header>
                    <header className="isHome header-movil">
                        <div className="container-logo">
                            <h1><span className="red">E</span>duardo <span className="red">B</span>lanco</h1>
                        </div>
                        <nav>
                            <img src={imgMenu} alt={imgMenu} className="menu-hamburguer" onClick={this.Menu}/>
                            <ul className="menu ul-movil">
                                <li onClick={this.HideMenuMovil}><HashLink smooth to="#slider" >Inicio</HashLink></li>
                                <li onClick={this.HideMenuMovil}><HashLink smooth to="#acerca" >Acerca de mí</HashLink></li>
                                <li onClick={this.HideMenuMovil}><HashLink smooth to="#cv" >Curriculum Vitae</HashLink></li>
                                <li onClick={this.HideMenuMovil}><HashLink smooth to="#proyectos" >Proyectos</HashLink></li>
                                <li onClick={this.HideMenuMovil} className="btn-contact"><HashLink  smooth to="#contacto">Contacto</HashLink></li>
                            </ul>
                        </nav>
                    </header>
                </React.Fragment>
            );
        }
    }
}

export default Header;